.header {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 15px 30px;
    grid-gap: 20px;
    border-bottom: 1px solid #787878;
    margin-left: 20px;
    z-index: 10;
}
.header svg {
    font-size: 22px;
    color: white;
    cursor: pointer;
}
/* style.css */

/* ... your existing styles ... */

/* Center the settings form */
.settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Set a minimum height to center vertically */
  }
  
  .setting {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .save-button {
    margin-top: 20px;
  }




  
  /* Hide menu icon and keep the logout button on larger screens */
  .menuIcon {
    display: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .logoutIcon {
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px; /* To ensure it's aligned properly */
  }
  
  /* Media query for smaller screens (less than 1000px) */
  @media (max-width: 1000px) {
  
    .menuItems {
      display: none; /* Hide menu items by default */
      position: absolute;
      top: 50px;
      left: 10px;
      background-color: white;
      border: 1px solid #ccc;
      padding: 10px;
      z-index: 1000;
    }
  
    .header {
      justify-content: space-between; /* Keep logout on right and menu on left */
    }
  }


/* Mobile Sidebar styles */
/* Close icon container */
.closeIconContainer {
  display: flex;
  justify-content: flex-end; /* Align the icon to the right */
}

/* Sidebar container */
.mobile-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background-color: rgba(25, 161, 95, 0.8);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* Close icon styles */
.closeIcon {
  font-size: 24px;
  cursor: pointer;
}


  
  /* Show the sidebar when toggled */
  .mobile-sidebar.show {
    display: block;
  }
  
  /* Media query for screens less than 1000px */
  @media (max-width: 1000px) {
    .menuIcon {
      display: block;
    }
    
    .mobile-sidebar {
      display: block;
      background-color: transparent !important;
    }
  }
  
  @media (min-width: 1000px) {
    .mobile-sidebar {
      display: none;
      /* background-color: transparent !important; */
    }
  }