::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.new-chat__form {
  position: fixed;
  left: 60%;
  z-index: 2;
  top: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 80%;
  border: 1px solid #d1d1d1;
  background-color: white;

  display: flex;
  width: 500px;
  flex-direction: column;
  transform: translate(-50%, -50%);
  border-radius: 20px;

  & .create__group-btn {
    position: absolute;
    bottom: -45px;
    left: 50%;
    padding: 10px 20px;
    background-color: rgb(51, 144, 236);
    border: none;
    color: white;
    font-weight: 600;
    border-radius: 20px;
    transform: translateX(-50%);
  }
  & .add-users__btn {
    padding: 10px 20px;
    background-color: rgb(51, 144, 236);
    border: none;
    color: white;
    font-weight: 600;
    border-radius: 20px;
    width: 50%;
    margin: 0 auto;
  }
  & .close__btn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    width: 40px !important;
    height: 40px !important;
  }

  & .found__users {
    padding: 0px 15px;
    overflow-y: scroll;
  }

  & .found__users {
    width: 100% !important;
    margin: 0 !important;
    flex: 0 1 100%;
  }

  & h1 {
    font-size: 20px;
    color: black;
    padding-bottom: 30px;
    padding-top: 40px;
    align-self: center;
  }

  & .find__user-container {
    display: flex;
    padding: 0px 15px;
    margin-bottom: 10px;
    margin: 10px auto;

    & input {
      border: none;
      min-width: 400px;
      padding: 10px 10px;
      background: white;
      border-radius: 30px 0px 0px 30px;
      padding-left: 10px;
      border: 1px solid #d3d3d3;
      border-right: none;
      & [type="text"] {
        height: 24px;
        font-size: 15px;
        padding-left: 25px;
      }
      &::placeholder {
        color: grey;
        font-size: 18px;
      }
    }

    & .find__user-btn {
      background-color: #c497f5;
      border-radius: 0px 30px 30px 0px;
      padding: 0px 20px;
      border: none;
    }
  }
}

@media screen and (max-width: 1285px) {
  .new-chat__form {
    left: 65%;
  }
}

@media screen and (max-width: 992px) {
  .new-chat__form {
    left: 67%;
  }
}

@media screen and (max-width: 767px) {
  .new-chat__form {
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    transform: translate(0, 0);
    border-radius: 0;
  }
}
