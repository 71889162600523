.new-chat__form {
  & .users__in-group {
    display: flex;
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
    flex-wrap: wrap;
    & > * {
      padding-right: 20px;
    }
  }
  &.create {
    padding-top: 30px;
  }
  & .chat__img {
    & img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  & .chat__name {
    margin: 0 auto;
    width: 90%;
    height: 15px;
    height: 50px;
    border-radius: 30px;
    border: 1px solid #dbdbdb;
    margin-top: 20px;
    padding-left: 20px;
  }
}
