.userContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: black;

  .tableInfo {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .tableData {
    // min-height: 50vh;
    padding-left: 20px;
    padding-right: 20px;
  }

  .paginationComp {
    width: 100%;
    min-height: 6vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 20px;


    .ant-pagination-item-active {
      background-color: black !important;
    }

    svg {
      color: white !important;
    }
    a {
      color: white !important;
    }
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  padding-top: 20;

  .inputContainer {
    display: flex;
    justify-content: space-between;

    .spanData {
      span {
        font-weight: bold;
      }
    }

    .inputdata {
      width: 70%;
    }
  }
}

@media (max-width: 1000px) {
  .responsivetable {
    display: flex;
    flex-direction: column; /* Stack the table vertically */
    width: 100vw; /* Make the table span the full viewport width */
    // overflow-x: auto; /* Enable horizontal scrolling */
    // margin-top: 60px; /* Optional: to adjust spacing */
    min-height: fit-content;
  }
}

@media (max-width: 1000px) {
  .responsivetableplaylist {
    display: flex;
    flex-direction: column; /* Stack the table vertically */
    width: 60vw; /* Make the table span the full viewport width */
    overflow-x: auto; /* Enable horizontal scrolling */
    margin-top: auto; /* Optional: to adjust spacing */
  }
}


@media (max-width: 1000px) {
  .PlayListVideosTable{
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .playlistdetailstableee{
    width: 100%;
  }
}

.playlistdetailstableee{
  width: 60%;
}

.playlistimgtext{
  display: flex;
   flex-direction: row;
    gap: 30px;
     width: 100%;
  }

@media (max-width: 1000px) {
  .playlistimgtext {
    display: flex;
    gap: 0px;
    flex-direction: column;
    width: 100%;
  }
}

.thumbnailimageofplaylist{
 width: 120px;
  max-height: 100px;
   cursor: pointer;
}


@media (max-width: 1000px) {
  .thumbnailimageofplaylist {
    width: 60px;
    max-height: 60px;
     cursor: pointer;
  }
}

.playlistdetailimage{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 1000px) {
  .playlistdetailimage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
}