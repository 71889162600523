.group-list {
  & .group-list__avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  & .group-username {
  }
}
