.found__user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  & .add-status {
    background-color: transparent;
    color: #007bad;
    border: 1px solid #36b6eb;
    padding: 10px 20px;
    border-radius: 10px;
    transition: 0.2s;
    cursor: pointer;
    &.add:hover {
      background: #31baf3;
      color: #ffffff;
    }
    &.delete {
      background: #31baf3;
      color: #ffffff;
    }
  }
  & .add__btn {
    cursor: pointer;
    border: none;
    color: white;
    background-color: #7687ff;
    height: 100%;
    padding: 10px 5px;
    font-weight: 600;
    letter-spacing: 0.05cm;
    transition: 0.3s ease-in-out;

    &:hover {
      color: black;
    }
  }
  & .user__main-info {
    display: flex;
    flex: 0 0 70%;
    align-items: center;
  }
  & .user__name-info {
    text-align: left;

    & .user__full-name {
      color: black;
    }
    & .user__login {
      color: black;
      font-size: 14px;
    }
  }
  &:not(:last-child) {
    padding-bottom: 10px;
  }

  & .user__avatar {
    width: 60px;
    height: 60px;
    margin-right: 15px;
    & img {
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
      height: 100%;
    }
  }
}
