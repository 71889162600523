/*  */
.ant-table-pagination-right {
  display: none !important;
}
@font-face {
  font-family: michroma;
  src: url(./Michroma/Michroma-Regular.ttf);
}
*,body {
  font-family: michroma;
}
.ant-table-content table tbody tr td {
  font-size: 12px;
}