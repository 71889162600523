.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  background-color: rgba(39, 253, 150, 0.8);
  color: black;
  padding: 20px;
  height: 100vh; /* Updated to 100vh to use full viewport height */

  .inputFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Ensures it takes full width */
  }
}

/* Media Queries */
@media (max-width: 1024px) {
  .login-form {
    padding: 10px; /* Reduce padding for medium screens */
  }
}

@media (max-width: 768px) {
  .login-form {
    width: 90%; /* Adjust width for small tablets */
  }

  .inputFlex {
    flex-direction: column; /* Stack input fields vertically */
    width: 100%; /* Ensure full width */
  }
}

@media (max-width: 450px) {
  .login-form {
    padding: 5px; /* Further reduce padding on small screens */
    width: auto !important;
    min-width: 300px; /* Ensure the minimum width for usability */
    max-height: 100vh !important;
    overflow: hidden !important; /* Avoid overflow */
  }

  h1 {
    font-size: 36px; /* Adjust title size for small screens */
  }

  h3 {
    font-size: 20px; /* Adjust welcome message size */
  }

  .inputFlex {
    width: 100%; /* Ensure full width for inputs */
  }
}
