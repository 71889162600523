.member {
  display: flex;
  align-items: center;
  padding: 10px;
  & .member__info-container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .member__name-activity {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    & .remove__user-container {
      background: white;
      padding: 5px 10px;
      border-radius: 25%;
      border: 1px solid #dbdfdf;
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        background: #ededed;
      }
    }
  }
  & .member__photo-container {
    margin-right: 10px;
    border-radius: 50%;
    width: 60px;
    height: 60px;

    & .member__avatar-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    & .user__no-img {
    }
  }
}
