.add__user {
  position: fixed !important;
  left: 50%;
  top: 50%;
}
.added__users-container {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  & > *:not(:last-child) {
    padding-right: 20px;
  }
}
.profile__info {
  position: absolute;
  border-left: 1px solid #e7e7e7;
  z-index: 21;
  transform: translateX(100%);
  width: 30%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  & .new-chat__container {
    & .new-chat__form {
      width: 100%;
      top: 50%;
      left: 0;
      z-index: 22;
    }
  }

  @media screen and (max-width: 1440px) {
    width: 50%;
  }
  @media screen and (max-width: 1100px) {
    width: 70%;
  }
  @media screen and (max-width: 820px) {
    width: 100%;
  }

  transition: 0.2s;
  right: 0;
  & .profile__header {
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    flex: 0 1 9.5%;

    & span {
      margin: 0 auto;
      font-family: "Roboto";
      color: black;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }
  &.show {
    background: white;
    transform: translateX(0);
    transform: translate(0);
  }

  & .profile__group-members {
    height: 68%;
    background: white;
    & .group__members-header {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      align-items: center;
      & .group__members-add {
        cursor: pointer;
      }
      & .group-members__title {
        font-weight: 500;
      }
    }
    & .members__container {
      overflow-y: scroll;
      height: 100%;
    }
  }
  & .profile__main-info {
    height: 50%;
    width: 100%;

    & .profile__img-container {
      height: 100%;
      width: 100%;
      position: relative;
      & .user__no-img {
        &.profile {
          width: 100%;
          height: 100%;
          border-radius: 0;

          & .name {
            font-size: 6rem;
          }
        }
      }
      &::after {
        position: absolute;
        width: 100%;
        height: 15%;
        content: "";
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        bottom: 0;
        left: 0;
      }
      & .user__avatar-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      & .profile__user-info {
        position: absolute;
        text-align: left;
        bottom: 10px;
        z-index: 21;
        left: 20px;
        color: black;

        & .members__count {
          line-height: 1.125rem;
          color: #afb4b7;
          font-size: 0.85rem;
        }

        & .last__activity {
          line-height: 1.125rem;
          color: #afb4b7;
        }
      }
    }
  }
}
