.sidebar-header__button {
  position: relative;
  width: 30px;
  cursor: pointer;
}

.sidebar-header__button:before,
.sidebar-header__button:after,
.sidebar-header__button div {
  background: #000;
  content: "";
  display: block;
  height: 3px;
  border-radius: 3px;
  margin: 7px 0;
  transition: 0.5s;
}

.sidebar__container {
  flex: 0 0 20%;
  max-width: 20%;
  display: flex;
  flex-direction: column;
  background-color: #fffdfd;
  border-right: 1px solid #c4c2c2;
  position: relative;

  & .chat-create-menu {
    position: absolute;
    top: 80%;
    left: 10%;
    text-align: left;
    min-width: 210px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: white;
    transition: 0.2s ease-in;
    &.hide {
      opacity: 0;
      transform: scale(0);
    }
    & ul {
      margin: 0 auto;

      & li {
        cursor: pointer;
        border-radius: 10px;

        padding-left: 20px;
        padding-top: 10px;
        &:hover {
          background-color: whitesmoke;
          width: 100%;
        }

        padding-bottom: 20px;
      }
    }
  }

  & .loader,
  & .loader:before,
  & .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  & .loader {
    color: #282828;
    font-size: 6px;

    position: relative;
    text-indent: -9999em;
    top: 38%;
    left: 50%;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0) translate(-50%, -50%);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  & .loader:before,
  & .loader:after {
    content: "";
    position: absolute;
    top: 0;
  }
  & .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  & .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }

  & .sidebar-chats__container {
    position: relative;
    cursor: pointer;
    padding-top: 20px;
    overflow-y: scroll;
    height: 87%;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
  }

  & .sidebar-header {
    display: flex;
    padding: 20px 10px;
    justify-content: space-between;
    align-items: center;

    & .context__menu {
      transition: 0.2s;
      position: absolute;
      color: #000;
      left: 2%;
      top: 8%;
      background-color: white;
      cursor: pointer;
      border: 0.5px solid #d9d4d4;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      &.hide {
        opacity: 0;
        transform: scale(0);
      }
      & ul {
        padding: 10px 20px;
      }
    }
  }
  & .sidebar-user__info {
    overflow-x: hidden;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    cursor: pointer;

    & .sidebar-user__name {
      color: black;
      font-size: 16px;
      padding-left: 20px;
      max-width: 80%;
    }
    & .sidebar-img__container {
      width: 50px;
      height: 50px;

      border-radius: 50%;

      & .user__no-img {
        &.main {
          width: 100%;
          height: 100%;
        }
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  & .sidebar-add__newchat {
    position: absolute;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2c85becc;
    left: 75%;
    top: 85%;

    width: 50px;
    height: 50px;
    color: black;
    cursor: pointer;

    margin-bottom: 10px;
    text-transform: capitalize;

    transition: 0.3s ease-in-out;
    &.hide {
      opacity: 0;
      transform: translateY(100px);
    }
  }

  & .sidebar-search__container {
    padding-bottom: 20px;
  }
  & .sidebar-search__chat {
    width: 90%;
    align-self: center;
    border: 1px solid #cfcfcf;
    padding: 10px 10px;
    background: transparent;
    border-radius: 30px;
    & [type="text"] {
      height: 24px;
      font-size: 15px;
      padding-left: 25px;
    }
    &::placeholder {
      color: #939393;
    }
  }
}

@media (max-width: 1500px) {
  .sidebar__container {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (max-width: 1300px) {
  .sidebar__container {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media screen and (max-width: 992px) {
  .sidebar__container {
    flex: 0 0 35%;
    max-width: 35%;
  }
}
@media (max-width: 767px) {
  .sidebar__container {
    transform: translateX(-200%);
    transition: 0.2s;
    display: none;
    &.show {
      display: block;
      flex: 0 0 100%;
      transform: translateX(0);

      max-width: 100%;
    }
  }
}
