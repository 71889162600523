.responsive-container {
  display: flex;
  gap: 20px;

  .chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #fff;
    flex-direction: column;

    canvas {
      max-height: 300px !important;
    }
  }
}

@media screen and (max-width: 1000px) {
  .responsive-container {
    flex-direction: column; /* Stack the charts vertically */
  }
}