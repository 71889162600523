.userContainerpl {
  // width: 100%;
  // height: 90vh;
  display: flex;
  flex-direction: column;
  max-height: fit-content;
  // justify-content: space-between;
  max-height: 550px; overflow-y: scroll;

  .ant-table{
    min-height:400px !important;
    max-height: 400px !important;

  }

  .tableDatapl {
    // min-height: 70vh;
    padding-left: 20px;
    padding-right: 20px;




    .ant-table-body{
      min-height:400px !important;
    }

  }

  .paginationComp {
    width: 100%;
    min-height: 6vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 20px;


    .ant-pagination-item-active {
      background-color: black !important;
    }

    svg {
      color: rgb(19, 8, 8) !important;
    }
    a {
      color: rgb(22, 15, 15) !important;
    }
  }
  /* Apply padding and margin styles for the entire table */
.ant-table-cell {
  padding: 7.9px !important; /* Adjust this value to reduce the padding */
}

// .custom-table {
//   width: 100%; /* or a specific width like 800px */
//   height: 100%; /* Let the height adjust automatically */
//   overflow: auto; /* Add scrolling if content exceeds height */
//   margin: 0 auto; /* Center the table */
// }

.site-layout-background {
  display: grid;
  // grid-template-columns: 1fr 350px;
  grid-gap: 0px;
}

h1 {
  font-size: 30px;
}

::-webkit-scrollbar {
  display: none;
}

.chartjs {
  width: 100%; /* This will make the chart take full width */
}

.Video-Growth-Over-Time{
  color: white;
}

.videotable-data{
  padding-left: 20px;
  padding-right: 20px;
  width:"100%";
} 
}
